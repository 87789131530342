import React, { useState, useEffect } from "react";

const Gastos = () => {
  const [expenses, setExpenses] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseDescription, setExpenseDescription] = useState("");
  const [expenseDate, setExpenseDate] = useState(
    new Date().toISOString().split("T")[0] // Formato de fecha para el input date
  );
  const [filterCategory, setFilterCategory] = useState("");
  const [totalExpenses, setTotalExpenses] = useState(0);

  // Función para formatear el número como moneda CLP
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(value);
  };

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await fetch("http://localhost:5000/expenses");
        const data = await response.json();
        setExpenses(data);

        // Calcular el total inicial
        const total = data.reduce((acc, expense) => acc + expense.amount, 0);
        setTotalExpenses(total);
      } catch (error) {
        console.error("Error al cargar los gastos:", error);
      }
    };

    fetchExpenses();
  }, []);

  const handleAddExpense = async (e) => {
    e.preventDefault();

    if (parseFloat(expenseAmount) > 0) {
      const newExpense = {
        description: expenseDescription,
        amount: parseFloat(expenseAmount),
        category: expenseCategory,
        date: expenseDate,
      };

      try {
        const response = await fetch("http://localhost:5000/api/expenses", {
          // Cambia el puerto
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newExpense),
        });

        if (!response.ok) {
          throw new Error("Error al guardar el gasto");
        }

        const savedExpense = await response.json();
        setExpenses((prevExpenses) => [
          ...prevExpenses,
          { ...newExpense, id: savedExpense.id },
        ]);

        // Limpiar los campos del formulario
        setExpenseAmount("");
        setExpenseCategory("");
        setExpenseDescription("");
        setExpenseDate(new Date().toISOString().split("T")[0]);
      } catch (error) {
        console.error("Error al guardar el gasto:", error);
      }
    } else {
      alert("El monto del gasto debe ser mayor que 0");
    }
  };

  //  // Cargar los gastos desde localStorage
  //  useEffect(() => {
  //    const savedExpenses = localStorage.getItem("expenses");
  //    if (savedExpenses) {
  //      const parsedExpenses = JSON.parse(savedExpenses);
  //      setExpenses(parsedExpenses);
  //
  //      // Calcular el total inicial al cargar los datos
  //      const total = parsedExpenses.reduce(
  //        (acc, expense) => acc + expense.amount,
  //        0
  //      );
  //      setTotalExpenses(total);
  //    }
  //  }, []);
  //
  //  // Guardar los gastos en localStorage cada vez que cambian
  //  useEffect(() => {
  //    localStorage.setItem("expenses", JSON.stringify(expenses));
  //    // Actualizar el total de gastos cuando la lista cambia
  //    const total = expenses.reduce((acc, expense) => acc + expense.amount, 0);
  //    setTotalExpenses(total);
  //  }, [expenses]);

  //  // Agregar un nuevo gasto
  //  const handleAddExpense = (e) => {
  //    e.preventDefault();
  //
  //    if (parseFloat(expenseAmount) > 0) {
  //      const newExpense = {
  //        amount: parseFloat(expenseAmount),
  //        category: expenseCategory,
  //        description: expenseDescription,
  //        date: expenseDate,
  //      };
  //
  //      setExpenses((prevExpenses) => [...prevExpenses, newExpense]);
  //
  //      // Limpiar los campos del formulario
  //      setExpenseAmount("");
  //      setExpenseCategory("");
  //      setExpenseDescription("");
  //      setExpenseDate(new Date().toISOString().split("T")[0]);
  //    } else {
  //      alert("El monto del gasto debe ser mayor que 0");
  //    }
  //  };
  //
  // Filtrar los gastos por categoría
  const filteredExpenses = expenses.filter((expense) =>
    filterCategory
      ? expense.category.toLowerCase().includes(filterCategory.toLowerCase())
      : true
  );

  return (
    <div>
      <h1>Gestión de Gastos</h1>

      <div>
        <h2>Agregar Gasto</h2>
        <form onSubmit={handleAddExpense}>
          <div>
            <label>Descripción:</label>
            <input
              type="text"
              value={expenseDescription}
              onChange={(e) => setExpenseDescription(e.target.value)}
              required
            />
          </div>

          <div>
            <label>Monto:</label>
            <input
              type="number"
              value={expenseAmount}
              onChange={(e) => setExpenseAmount(e.target.value)}
              required
            />
          </div>

          <div>
            <label>Categoría:</label>
            <select
              value={expenseCategory}
              onChange={(e) => setExpenseCategory(e.target.value)}
              required
            >
              <option value="">Selecciona una categoría</option>
              <option value="Transporte">Transporte</option>
              <option value="Material">Material</option>
              <option value="Servicios">Servicios</option>
              <option value="Otros">Otros</option>
            </select>
          </div>

          <div>
            <label>Fecha:</label>
            <input
              type="date"
              value={expenseDate}
              onChange={(e) => setExpenseDate(e.target.value)}
              required
            />
          </div>

          <button type="submit">Agregar Gasto</button>
        </form>
      </div>

      <div>
        <h2>Filtros</h2>
        <input
          type="text"
          placeholder="Filtrar por categoría"
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
        />
      </div>

      <div>
        <h2>Lista de Gastos</h2>
        <ul>
          {filteredExpenses.map((expense, index) => (
            <li key={index}>
              {expense.description} - {formatCurrency(expense.amount)} -
              Categoría: {expense.category} - Fecha: {expense.date}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h3>Total de Gastos: {formatCurrency(totalExpenses)}</h3>
      </div>
    </div>
  );
};

export default Gastos;
