import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ invoices, expenses }) => {
  console.log("Invoices:", invoices); // Verifica si hay datos en invoices
  console.log("Expenses:", expenses); // Verifica si hay datos en expenses
  // Cálculos
  const totalFacturado = (invoices || []).reduce(
    (acc, invoice) => acc + invoice.totalAmount,
    0
  );
  const totalGastos = (expenses || []).reduce(
    (acc, expense) => acc + expense.amount,
    0
  );

  const balance = totalFacturado - totalGastos;

  // Generación dinámica de datos para el gráfico
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo"];

  // Asegurarte de que invoices y expenses son arrays antes de usar 'filter' y 'reduce'
  const invoiceData = months.map((month) => {
    return (invoices || [])
      .filter(
        (invoice) => new Date(invoice.date).getMonth() === months.indexOf(month)
      )
      .reduce((acc, invoice) => acc + invoice.totalAmount, 0);
  });

  const expenseData = months.map((month) => {
    return (expenses || [])
      .filter(
        (expense) => new Date(expense.date).getMonth() === months.indexOf(month)
      )
      .reduce((acc, expense) => acc + expense.amount, 0);
  });

  // Datos para el gráfico
  const chartData = {
    labels: months,
    datasets: [
      {
        label: "Facturación",
        data: invoiceData,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Gastos",
        data: expenseData,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
      },
    ],
  };

  // Ordenar facturas y gastos de manera descendente por fecha
  // Asegúrate de que el array no sea undefined ni null antes de usar 'sort'
  const sortedInvoices = (invoices || []).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const sortedExpenses = (expenses || []).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  // Verificación de que invoices y expenses son arrays
  if (!Array.isArray(invoices) || !Array.isArray(expenses)) {
    return <div>No hay datos disponibles para mostrar.</div>;
  }

  // Verificar si hay datos disponibles
  if (invoices.length === 0 || expenses.length === 0) {
    return <div>No hay datos disponibles para mostrar.</div>;
  }

  return (
    <div>
      <h1>Dashboard de Finanzas</h1>
      <div>
        <h3>Resumen de KPIs</h3>
        <ul>
          <li>
            Total Facturado:{" "}
            {new Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP",
            }).format(totalFacturado)}
          </li>
          <li>
            Total de Gastos:{" "}
            {new Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP",
            }).format(totalGastos)}
          </li>
          <li>
            Balance:{" "}
            {new Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP",
            }).format(balance)}
          </li>
        </ul>
      </div>

      <div>
        <h3>Gráfico de Facturación vs Gastos</h3>
        <Line data={chartData} />
      </div>

      <div>
        <h3>Facturas Recientes</h3>
        <ul>
          {sortedInvoices.slice(0, 5).map((invoice, index) => (
            <li key={index}>
              {invoice.client} -{" "}
              {new Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP",
              }).format(invoice.totalAmount)}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h3>Gastos Recientes</h3>
        <ul>
          {sortedExpenses.slice(0, 5).map((expense, index) => (
            <li key={index}>
              {expense.description} -{" "}
              {new Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP",
              }).format(expense.amount)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
