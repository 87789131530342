const BASE_URL = "http://localhost:5000/api";

const login = async (username, password) => {
  return await post("/login", { username, password });
};

const post = async (endpoint, body) => {
  try {
    const response = await fetch(BASE_URL + endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: typeof body === "string" ? body : JSON.stringify(body),
    });
    if (!response.ok) throw { myMsg: "Error al hacer login" };
    return await response.json();
  } catch (e) {
    const msg = e.myMsg ?? "Error al comunicarse con el servidor";
    throw new Error(msg);
  }
};

export { login };
