import React, { useState, useEffect } from "react";

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskStatus, setTaskStatus] = useState("Pendiente");
  const [taskDueDate, setTaskDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [users, setUsers] = useState([]); // Estado para almacenar usuarios
  const [selectedUserId, setSelectedUserId] = useState(""); // ID del usuario seleccionado

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch("http://localhost:5000/tasks");
        const data = await response.json();
        setTasks(data);
      } catch (error) {
        console.error("Error al cargar las tareas:", error);
      }
    };

    const fetchUsers = async () => {
      // Función para cargar usuarios
      try {
        const response = await fetch("http://localhost:5000/users");
        const data = await response.json();
        setUsers(data); // Asignamos los usuarios al estado
      } catch (error) {
        console.error("Error al cargar los usuarios:", error);
      }
    };

    fetchTasks();
    fetchUsers(); // Llamamos la función para cargar usuarios
  }, []);

  const handleAddTask = async (e) => {
    e.preventDefault();
    const newTask = {
      title: taskTitle,
      description: taskDescription,
      status: taskStatus,
      dueDate: taskDueDate,
      assignedUserId: selectedUserId, // Asignamos el ID del usuario a la tarea
    };

    try {
      const response = await fetch("http://localhost:5000/api/tasks", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newTask),
      });

      if (!response.ok) throw new Error("Error al guardar la tarea");

      const savedTask = await response.json();
      setTasks((prevTasks) => [...prevTasks, { ...newTask, id: savedTask.id }]);

      setTaskTitle("");
      setTaskDescription("");
      setTaskStatus("Pendiente");
      setTaskDueDate(new Date().toISOString().split("T")[0]);
      setSelectedUserId(""); // Reseteamos la selección del usuario
    } catch (error) {
      console.error("Error al guardar la tarea:", error);
    }
  };

  const handleDeleteTask = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/api/tasks/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Error al eliminar la tarea");

      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
    } catch (error) {
      console.error("Error al eliminar la tarea:", error);
    }
  };

  return (
    <div>
      <h1>Gestión de Tareas</h1>

      <form onSubmit={handleAddTask}>
        <input
          type="text"
          placeholder="Título"
          value={taskTitle}
          onChange={(e) => setTaskTitle(e.target.value)}
          required
        />
        <textarea
          placeholder="Descripción"
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
          required
        />
        <select
          value={taskStatus}
          onChange={(e) => setTaskStatus(e.target.value)}
        >
          <option value="Pendiente">Pendiente</option>
          <option value="Completada">Completada</option>
        </select>
        <input
          type="date"
          value={taskDueDate}
          onChange={(e) => setTaskDueDate(e.target.value)}
          required
        />
        <select
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
          required
        >
          <option value="">Seleccionar usuario</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.username} - {user.role}
            </option>
          ))}
        </select>
        <button type="submit">Agregar Tarea</button>
      </form>

      <ul>
        {tasks.map((task) => (
          <li key={task.id}>
            <h3>{task.title}</h3>
            <p>{task.description}</p>
            <p>{task.status}</p>
            <p>{task.dueDate}</p>
            <p>Asignada a: {task.assignedUserId}</p>{" "}
            {/* Mostrar el usuario asignado */}
            <button onClick={() => handleDeleteTask(task.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tasks;
