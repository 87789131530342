import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";

const Home = () => {
  const { user } = useAuth();

  const images = [
    "/assets/images/imagen-0.jpg",
    "/assets/images/imagen-1.jpg",
    "/assets/images/imagen-2.jpg",
  ];

  const [currentImage, setCurrentImage] = React.useState(0);

  const changeImage = (direction) => {
    setCurrentImage((prev) =>
      direction === "next"
        ? (prev + 1) % images.length
        : (prev - 1 + images.length) % images.length
    );
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <h1>Bienvenido, {user.username}</h1>
      <img src={images[currentImage]} alt="Imagen" />
      <div>
        <button onClick={() => changeImage("prev")}>Anterior</button>
        <button onClick={() => changeImage("next")}>Siguiente</button>
      </div>
    </div>
  );
};

export default Home;
