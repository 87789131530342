import React from "react";
import { Navigate } from "react-router-dom";

const Landing = () => {
  const images = ["/assets/images/imagen-0.jpg", "/assets/images/imagen-1.jpg"];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h1>Bienvenido</h1>
        </div>
      </div>
      <div className="row">
        {images.map((i) => (
          <div className="col-12 col-lg-6">
            <img className="img-fluid" src={i} alt="Imagen" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Landing;
