import React, { useState, useEffect } from "react";

const Facturacion = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoiceClient, setInvoiceClient] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [iva, setIva] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [invoiceDescription, setInvoiceDescription] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(
    new Date().toISOString().split("T")[0] // Formato yyyy-MM-dd
  );
  const [paymentMethod, setPaymentMethod] = useState("efectivo");
  const [facturada, setFacturada] = useState(false);
  const [clients, setClients] = useState([]);

  // Formatear moneda
  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(value);

  // Calcular IVA y total
  const calculateTotal = (net) => {
    const netValue = parseFloat(net) || 0;
    const ivaAmount = (netValue * 19) / 100;
    const total = netValue + ivaAmount;
    setIva(ivaAmount);
    setTotalAmount(total);
  };

  // Cambiar el estado de "Facturada" de una factura
  const toggleFacturadaStatus = (index) => {
    const updatedInvoices = invoices.map((invoice, i) =>
      i === index ? { ...invoice, facturada: !invoice.facturada } : invoice
    );
    setInvoices(updatedInvoices);
  };

  // Manejar el envío del formulario
  const handleAddInvoice = async (e) => {
    e.preventDefault();

    if (!invoiceClient || parseFloat(netAmount) <= 0) {
      alert("El cliente es obligatorio y el monto neto debe ser mayor que 0.");
      return;
    }

    const newInvoice = {
      client: invoiceClient,
      netAmount: parseFloat(netAmount),
      iva,
      totalAmount,
      description: invoiceDescription,
      date: invoiceDate,
      paymentMethod,
      facturada,
    };

    try {
      const response = await fetch("http://localhost:5000/facturacion", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newInvoice),
      });

      if (!response.ok) {
        throw new Error("Error al guardar la factura.");
      }

      const data = await response.json();
      setInvoices([...invoices, { ...newInvoice, id: data.id }]);

      // Reiniciar formulario
      setInvoiceClient("");
      setNetAmount("");
      setIva(0);
      setTotalAmount(0);
      setInvoiceDescription("");
      setInvoiceDate(new Date().toISOString().split("T")[0]);
      setPaymentMethod("efectivo");
      setFacturada(false);
    } catch (error) {
      console.error("Error al agregar la factura:", error);
    }
  };

  // Cargar clientes desde el servidor
  const cargarClientes = async () => {
    try {
      const response = await fetch("http://localhost:5000/clientes");
      if (!response.ok) throw new Error("Error al cargar los clientes.");
      const data = await response.json();
      setClients(data);
      if (data.length > 0) setInvoiceClient(data[0].nombreCliente); // Selecciona el primer cliente por defecto
    } catch (error) {
      console.error(error.message);
    }
  };

  // Cargar facturas desde el servidor
  const cargarFacturas = async () => {
    try {
      const response = await fetch("http://localhost:5000/facturacion");
      if (!response.ok) throw new Error("Error al cargar las facturas.");
      const data = await response.json();
      setInvoices(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  // useEffect para cargar datos al inicio
  useEffect(() => {
    cargarClientes();
    cargarFacturas();
  }, []);

  return (
    <div>
      <h1>Gestión de Facturación</h1>

      <h2>Agregar Factura</h2>
      <form onSubmit={handleAddInvoice}>
        <div>
          <label>Cliente:</label>
          <select
            value={invoiceClient}
            onChange={(e) => setInvoiceClient(e.target.value)}
            required
          >
            <option value="">Seleccionar Cliente</option>
            {clients.map((client) => (
              <option key={client.id} value={client.nombreCliente}>
                {client.nombreCliente}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Monto Neto:</label>
          <input
            type="number"
            value={netAmount}
            onChange={(e) => {
              setNetAmount(e.target.value);
              calculateTotal(e.target.value);
            }}
            required
          />
        </div>

        <div>
          <label>Descripción:</label>
          <textarea
            value={invoiceDescription}
            onChange={(e) => setInvoiceDescription(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Fecha:</label>
          <input
            type="date"
            value={invoiceDate}
            onChange={(e) => setInvoiceDate(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Forma de Pago:</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
          >
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia</option>
            <option value="cheque">Cheque</option>
          </select>
        </div>

        <button type="submit">Agregar Factura</button>
      </form>

      <h3>Lista de Facturas</h3>
      <div className="invoice-cards">
        {invoices.map((invoice, index) => (
          <div className="invoice-card" key={invoice.id || index}>
            <h4>Factura de {invoice.client}</h4>
            <p>
              <strong>Monto Neto:</strong> {formatCurrency(invoice.netAmount)}
            </p>
            <p>
              <strong>IVA:</strong> {formatCurrency(invoice.iva)}
            </p>
            <p>
              <strong>Total:</strong> {formatCurrency(invoice.totalAmount)}
            </p>
            <p>
              <strong>Descripción:</strong> {invoice.description}
            </p>
            <p>
              <strong>Fecha:</strong> {invoice.date}
            </p>
            <p>
              <strong>Forma de Pago:</strong> {invoice.paymentMethod}
            </p>
            <button onClick={() => toggleFacturadaStatus(index)}>
              {invoice.facturada
                ? "Desmarcar como Facturada"
                : "Marcar como Facturada"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facturacion;
