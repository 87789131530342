// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  background-color: #2c3e50;
  color: white;
  padding: 1rem;
  text-align: center;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar li {
  display: inline;
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.container {
  padding: 20px;
}

.form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.form-container input {
  padding: 10px;
  margin: 5px 0;
  width: 250px;
}

.form-container button {
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #2980b9;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* App.css */\r\n\r\nbody {\r\n  font-family: Arial, sans-serif;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.navbar {\r\n  background-color: #2c3e50;\r\n  color: white;\r\n  padding: 1rem;\r\n  text-align: center;\r\n}\r\n\r\n.navbar ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.navbar li {\r\n  display: inline;\r\n  margin: 0 15px;\r\n}\r\n\r\n.navbar a {\r\n  color: white;\r\n  text-decoration: none;\r\n}\r\n\r\n.container {\r\n  padding: 20px;\r\n}\r\n\r\n.form-container {\r\n  margin-top: 20px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.form-container input {\r\n  padding: 10px;\r\n  margin: 5px 0;\r\n  width: 250px;\r\n}\r\n\r\n.form-container button {\r\n  padding: 10px;\r\n  background-color: #3498db;\r\n  color: white;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\n\r\n.form-container button:hover {\r\n  background-color: #2980b9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
