import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";

const pages = [
  { name: "Home", path: "/" },
  { name: "Login", path: "/login" },
  { name: "Dashboard", path: "/admin/dashboard" },
  { name: "Clientes", path: "/admin/clientes" },
  { name: "Facturación", path: "/admin/facturacion" },
  { name: "Gastos", path: "/admin/gastos" },
  { name: "Tareas", path: "/admin/tareas" },
  { name: "Trabajadores", path: "/usuario/trabajadores" },
  { name: "Inventario", path: "/usuario/inventario" },
  { name: "Trabajadores Asignados", path: "/cliente/trabajadores-asignados" },
  { name: "Documentacion", path: "/cliente/documentacion" },
];

const Navbar = () => {
  const { user, logout } = useAuth();

  return (
    <nav className="navbar">
      <ul>
        {[
          pages
            .filter(
              (page) =>
                (user && page.path.startsWith(`/${user.role}`)) ||
                page.path === "/login" ||
                page.path === "/"
            )
            .map((page) => (
              <li>
                <Link to={page.path}>{page.name}</Link>
              </li>
            )),
        ]}
      </ul>
      <ul>
        <li>
          <a href="./#" onClick={logout}>
            Logout
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
