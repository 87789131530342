import React, { useState, useMemo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home"; // Asegúrate de que Home.js esté en la carpeta ./components
import Login from "./pages/Login"; // Asegúrate de que Login.js esté en ./components
import Dashboard from "./pages/Admin/Dashboard"; // Y así para los demás archivos
import Clientes from "./pages/Admin/Clientes";
import Facturacion from "./pages/Admin/Facturacion";
import Gastos from "./pages/Admin/Gastos";
import Tareas from "./pages/Admin/Tareas";
import Trabajadores from "./pages/Usuario/Trabajadores";
import Inventario from "./pages/Usuario/Inventario";
import TrabajadoresAsignados from "./pages/Cliente/TrabajadoresAsignados";
import Documentacion from "./pages/Cliente/Documentacion";

import Navbar from "./components/Navbar";
import "./App.css";
import Landing from "./pages/Landing";
import { AuthProvider, useAuth } from "./components/AuthContext";

const App = () => {
  const { user } = useAuth();
  const [clients, setClients] = useState([
    { name: "Cliente 1", rut: "12.345.678-9" },
    { name: "Cliente 2", rut: "98.765.432-1" },
  ]);
  const isAuthenticated = useMemo(() => !!user, [user]);

  return (
    <Router>
      <Navbar />
      <div className="container-fluid">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/login" element={<Login />} />

          {/* Rutas protegidas */}
          <Route
            path="/admin/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/clientes"
            element={isAuthenticated ? <Clientes /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/facturacion"
            element={
              isAuthenticated ? <Facturacion /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/admin/gastos"
            element={isAuthenticated ? <Gastos /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/tareas"
            element={isAuthenticated ? <Tareas /> : <Navigate to="/login" />}
          />
          <Route
            path="/usuario/trabajadores"
            element={
              isAuthenticated ? <Trabajadores /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/usuario/inventario"
            element={
              isAuthenticated ? <Inventario /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/cliente/trabajadores-asignados"
            element={
              isAuthenticated ? (
                <TrabajadoresAsignados />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/cliente/documentacion"
            element={
              isAuthenticated ? <Documentacion /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
