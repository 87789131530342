import React, { useState } from "react";
import { decodeJwt } from "jose";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import { login as backendLogin } from "../services/backendClient";

const Login = () => {
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;

    if (!username || !password) {
      setError("Por favor, ingrese ambos campos");
      return;
    }

    try {
      const data = await backendLogin(username, password);
      login(decodeJwt(data.token));
      navigate("/");
      // const response = await fetch("http://localhost:3000/api/login", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ username, password }),
      // });
      // const data = await response.json();
      // if (response.ok) {
      //   login({ username: data.username, profile: data.profile });
      //   navigate("/"); // Redirige después de iniciar sesión
      // } else {
      //   setError(data.message || "Usuario o contraseña incorrectos");
      // }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div>
      <h2>Iniciar Sesión</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleLogin}>
        <input type="text" name="username" placeholder="Nombre de usuario" />
        <input type="password" name="password" placeholder="Contraseña" />
        <button type="submit">Iniciar Sesión</button>
      </form>
    </div>
  );
};

export default Login;
