import React, { useState, useEffect } from "react";

const comunas = [
  "Santiago",
  "Valparaíso",
  "Concepción",
  "La Serena",
  "Antofagasta",
  "Temuco",
  "Viña del Mar",
  "Rancagua",
  "Iquique",
  "Talca",
];

function Clientes() {
  const [clientes, setClientes] = useState([]);
  const [nuevoCliente, setNuevoCliente] = useState({
    nombreCliente: "",
    rut: "",
    domicilio: "",
    comuna: "",
    encargadoContrato: "",
    telefono: "",
  });

  //// Cargar los clientes desde localStorage
  //useEffect(() => {
  //  const storedClientes = JSON.parse(localStorage.getItem("clientes")) || [];
  //  setClientes(storedClientes);
  //}, []);

  useEffect(() => {
    cargarClientes();
    //    fetch("http://localhost:5000/clientes")
    //      .then((response) => response.json())
    //      .then((data) => {
    //        console.log("Clientes cargados:", data); // Verifica la respuesta
    //        setClientes(data);
    //      })
    //      .catch((error) => console.error("Error al cargar los clientes:", error));
  }, []);

  const formatRut = (rut) => {
    rut = rut.replace(/[^\dKk]/g, "");
    if (rut.length >= 8) {
      rut = rut.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
    }
    return rut;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rut") {
      setNuevoCliente((prevCliente) => ({
        ...prevCliente,
        rut: formatRut(value),
      }));
    } else {
      setNuevoCliente((prevCliente) => ({
        ...prevCliente,
        [name]: value,
      }));
    }
  };

  //const handleSubmit = (e) => {
  //  e.preventDefault();
  //  if (
  //    nuevoCliente.nombreCliente &&
  //    nuevoCliente.rut &&
  //    nuevoCliente.domicilio &&
  //    nuevoCliente.comuna &&
  //    nuevoCliente.encargadoContrato &&
  //    nuevoCliente.telefono
  //  ) {
  //    const updatedClientes = [...clientes, nuevoCliente];
  //    setClientes(updatedClientes);
  //    localStorage.setItem("clientes", JSON.stringify(updatedClientes));
  //    setNuevoCliente({
  //      nombreCliente: "",
  //      rut: "",
  //      domicilio: "",
  //      comuna: "",
  //      encargadoContrato: "",
  //      telefono: "",
  //    });
  //  }
  //};

  //const handleSubmit = (e) => {
  //  e.preventDefault();
  //  if (
  //    nuevoCliente.nombreCliente &&
  //    nuevoCliente.rut &&
  //    nuevoCliente.domicilio &&
  //    nuevoCliente.comuna &&
  //    nuevoCliente.encargadoContrato &&
  //    nuevoCliente.telefono
  //  ) {
  //    fetch("http://localhost:5000/clientes", {
  //      method: "POST",
  //      headers: {
  //        "Content-Type": "application/json",
  //      },
  //      body: JSON.stringify(nuevoCliente),
  //    })
  //      .then((response) => response.json())
  //      .then((data) => {
  //        setClientes([...clientes, { ...nuevoCliente, id: data.id }]);
  //        setNuevoCliente({
  //          nombreCliente: "",
  //          rut: "",
  //          domicilio: "",
  //          comuna: "",
  //          encargadoContrato: "",
  //          telefono: "",
  //        });
  //      })
  //      .catch((error) => console.error("Error al agregar cliente:", error));
  //  }
  //};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      nuevoCliente.nombreCliente &&
      nuevoCliente.rut &&
      nuevoCliente.domicilio &&
      nuevoCliente.comuna &&
      nuevoCliente.encargadoContrato &&
      nuevoCliente.telefono
    ) {
      fetch("http://localhost:5000/clientes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nuevoCliente),
      })
        .then((response) => response.json())
        .then(() => {
          // Después de agregar el cliente, vuelve a cargar la lista de clientes
          cargarClientes();

          // Limpiar los campos del formulario
          setNuevoCliente({
            nombreCliente: "",
            rut: "",
            domicilio: "",
            comuna: "",
            encargadoContrato: "",
            telefono: "",
          });
        })
        .catch((error) => console.error("Error al agregar cliente:", error));
    }
  };

  //const handleDelete = (index) => {
  //  const updatedClientes = clientes.filter((_, i) => i !== index);
  //  setClientes(updatedClientes);
  //  localStorage.setItem("clientes", JSON.stringify(updatedClientes));
  //};

  //  const handleDelete = (id) => {
  //    fetch(`http://localhost:5000/clientes/${id}`, {
  //      method: "DELETE",
  //    })
  //      .then(() => {
  //        setClientes(clientes.filter((cliente) => cliente.id !== id));
  //      })
  //      .catch((error) => console.error("Error al eliminar cliente:", error));
  //  };

  const handleDelete = (id) => {
    fetch(`http://localhost:5000/clientes/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        cargarClientes(); // Vuelve a cargar los datos después de eliminar
      })
      .catch((error) => console.error("Error al eliminar cliente:", error));
  };
  // Función para cargar la lista de clientes
  const cargarClientes = () => {
    fetch("http://localhost:5000/clientes")
      .then((response) => response.json())
      .then((data) => setClientes(data))
      .catch((error) => console.error("Error al cargar los clientes:", error));
  };

  //const handleEdit = (index) => {
  //  const clienteAEditar = clientes[index];
  //  setNuevoCliente(clienteAEditar);
  //  handleDelete(index);
  //};

  const handleEdit = (cliente) => {
    setNuevoCliente(cliente);
    //    handleDelete(cliente.id);
  };

  return (
    <div>
      <h2>Gestionar Clientes</h2>
      <form
        onSubmit={handleSubmit}
        style={{
          margin: "20px 0",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          Agregar Cliente
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "15px",
          }}
        >
          <div>
            <label>Nombre Cliente:</label>
            <input
              type="text"
              name="nombreCliente"
              value={nuevoCliente.nombreCliente}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "8px",
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div>
            <label>Rut:</label>
            <input
              type="text"
              name="rut"
              value={nuevoCliente.rut}
              onChange={handleChange}
              required
              maxLength="12"
              style={{
                width: "100%",
                padding: "8px",
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div>
            <label>Domicilio:</label>
            <input
              type="text"
              name="domicilio"
              value={nuevoCliente.domicilio}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "8px",
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div>
            <label>Comuna:</label>
            <select
              name="comuna"
              value={nuevoCliente.comuna}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "8px",
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              <option value="">Seleccionar Comuna</option>
              {comunas.map((comuna, index) => (
                <option key={index} value={comuna}>
                  {comuna}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Encargado Contrato:</label>
            <input
              type="text"
              name="encargadoContrato"
              value={nuevoCliente.encargadoContrato}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "8px",
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div>
            <label>Teléfono:</label>
            <input
              type="tel"
              name="telefono"
              value={nuevoCliente.telefono}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "8px",
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Agregar Cliente
          </button>
        </div>
      </form>

      <hr />
      <h3>Lista de Clientes</h3>
      <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th>Nombre Cliente</th>
            <th>RUT</th>
            <th>Domicilio</th>
            <th>Comuna</th>
            <th>Encargado Contrato</th>
            <th>Teléfono</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clientes.map((cliente) => (
            <tr key={cliente.id}>
              <td>{cliente.nombreCliente}</td>
              <td>{cliente.rut}</td>
              <td>{cliente.domicilio}</td>
              <td>{cliente.comuna}</td>
              <td>{cliente.encargadoContrato}</td>
              <td>{cliente.telefono}</td>
              <td>
                <button onClick={() => handleDelete(cliente.id)}>
                  Eliminar
                </button>
                <button onClick={() => handleEdit(cliente)}>Modificar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Clientes;
